.secnote-table{
    tbody td.reset-expansion-style{
        padding:0;
    }
}
.secnotelvlstyle{
    * {
        color:inherit;
    }
    .btn{
        color:#FFFFFF;
    }
    .btn-link{
        color:inherit;
    }
    a{
        text-decoration:underline;
    }
    &.level{
        &-ok{
            border-color:darken($success, 10%);
            background-color:$success;
            color:$success-contrast;
            *:not(td):not(.btn){ 
                text-decoration: line-through;
            }
            > *{
                border-color:darken($success, 10%); 
                
            }
        }
        &-info{
            border-color:darken($info, 10%);
            background-color:$info;
            color:$info-contrast;
            > *{
                border-color:darken($info, 10%);
            }
        }
        &-warning{
            border-color:darken($warning, 10%);
            background-color:$warning;
            color:$warning-contrast;
            > *{
                border-color:darken($warning, 10%);
            }
        }
        &-insecure{
            border-color:darken($danger, 10%);
            background-color:$danger;
            color:$danger-contrast;
            > *{
                border-color:darken($danger, 10%);
            }
        }
        &-fatal{
            font-weight:bold;
            border-color:darken($danger, 10%);
            background-color:$danger;
            color:$danger-contrast;
            > *{
                font-weight:bold;
                border-color:darken($danger, 10%);
            }
        }
    }
}
.secnote-row{
    @extend .secnotelvlstyle;
    opacity:.8;
    &.silenced{
        opacity:.5;
    }
    &:hover{
        opacity:1;
    }
}
.secnote-expand{
    @extend .secnote-row;
    opacity:1;
    &.silenced{
        opacity:1;
    }
    &.container-fluid{
        padding: 0.3rem;
    }
}

.table-striped tbody .secnote-row{
    @extend .secnote-row;
}

.secnote{
    @extend .alert;
    @extend .secnotelvlstyle;
    padding:0;
    @extend .py-2;
    margin-bottom: 1em;
    border-radius:0;
}