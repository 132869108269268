.form-group {
    margin-bottom: 0.3rem
}
label {
    margin-bottom: 0.1rem;
}

// ckeditor
.ck.ck-editor__top {
    position: relative;
}