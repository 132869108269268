.page-layout {
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow: visible;

    .content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: visible;
        overflow-y: auto;
        padding-bottom: 1em;
    }

    .sidebar {
        z-index: 1000;
        background-color: $secondary;
        color: $text-sidebar;
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0 0 300px;

        .menu-toggle {
            display: none;
        }

        .main-nav {
            flex-grow: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            background-color: $secondary;
        }

        .header {

            .brand {
                background-color: $tertiaire;
                width: 100%;
                text-align: center;
                position: relative;
                z-index: 1;
                padding-top: 8px;
                margin-bottom: -10px;
                height: 55px;

                &:after {
                    content: "";
                    width: 100px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    background: $tertiaire;
                    z-index: -1;
                    transform: skewX(-20deg);
                    transform-origin: 0 100px 0;
                    right: 0;
                    border-radius: 0 0 10px 0;
                }

                img {
                    height: 100%;
                }
            }
        }

        .nav-content {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            overflow: hidden;

            .main-menu {
                position: relative;
                flex-direction: column;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                padding-top: 10px;
                width: 100%;
                overflow-y: auto;
            }

            .sub-menu {
                position: relative;
                display: none;
                flex-direction: column;
                width: inherit;
                flex-grow: 1;
                padding: 15px 0;
                background: $secondary-light;
                overflow-y: auto;

                .button {
                    height: 44px;

                    &:hover {
                        background-color: mix($secondary-light, #fff, $weight: 80%);
                    }

                    &.header:hover {
                        background-color: inherit;
                        cursor: default;
                    }
                }
            }

            &.expanded {
                .main-menu {
                    flex: 0 0 80px;
                    max-width: 80px;

                    .button {
                        display: inline-block;
                        width: 100%;
                        padding: 0.6em 0 0.5em;
                        gap: 0.25em;

                        .icon {
                            font-size: 1.2em;
                            height: 1.2em;
                            width: 100%;
                        }
                        .label {
                            font-size: 0.7em;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
                .sub-menu {
                    display: flex;

                    .button{
                        .label {
                            font-size: 0.85em;
                        }
                    }
                }
            }
        }

        .footer {
            font-size: 0.8em;
            text-align: center;
            padding: 0.5em 0.2em;
            background: $secondary;

            .bottom-text {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 0.5em;
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        background: inherit;

        & + .menu {
            padding-top: 1em;
        }

        .header {
            padding: 10px 0;
        }
    }

    .button {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 0.5em;
        align-items: center;
        user-select: none;
        color: $text-sidebar;

        &:hover {
            background-color: mix($secondary, #fff, $weight: 80%);
        }

        &.active {
            color: $tertiaire;
            background-color: $secondary-light;

            &:hover {
                background-color: mix($secondary-light, #fff, $weight: 80%);
            }
        }

        .icon {
            min-width: 1em;
            width: 1em;
            height: 1em;
        }
        .label {
            flex-grow: 1;
            font-size: 1.2em;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            .extra-button{
                display: inline-block;
                .end-icon {
                    font-size: 1.4em;
                }
            }           
        }
        .outside {
            font-size: 0.8em;
        }
    }

    .header {
        .label {
            padding: 0 1em;
            font-size: 1em;
            font-weight: bold;
        }
    }

    .extra-button {
        color: $text-sidebar;

        &:hover {
            color: $tertiaire;
        }

        &.active {
            color: $tertiaire;
        }
    }

    .user-menu {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0.25em;
        padding: 0.5em 0;
        background: $secondary;
        border: 1px solid $usermenu-border-color;
        border-radius: 8px;

        .button {
            padding: 0.2em 1em;

            .icon {
                font-size: 1em;
            }
            .label {
                font-size: 0.6em;
            }
        }
    }
}

.responsive-chart {
    min-height: 450px;
    max-height: max(450px, 50vh);
}

.zone-card .responsive-chart {
    min-height: 150px;
}

.chart-tabs-card .card-header-tabs {
    margin: 0 0 20px 0;
}

@media (max-height: 400px) {
    .responsive-chart {
        max-height: max(450px, 90vh);
    }
}

@media only screen and (max-width: 768px) {

    .wide-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .responsive-chart,
    .zone-card .responsive-chart {
        min-height: 300px;
    }

    .page-layout {
        
        .sidebar {
            position: fixed;
            inset: 0 0 auto;

            .menu-toggle {
                display: block;
                position: relative;
                width: 60px;
                height: 45px;
                padding: 0;
                margin: 0;
                border: 0;
                outline: 0;
                box-shadow: none;
                float: right;

                span {
                    width: 20px;
                    height: 3px;
                    background: white;
                    border-radius: 3px;
                    position: absolute;
                    left: 20px;
                    transition: top .1s .1s, opacity .1s, transform .1s;
                    transition-timing-function: ease-out;

                    &:nth-child(1) {
                        top: 14px;
                    }

                    &:nth-child(2) {
                        top: 21px;
                    }

                    &:nth-child(3) {
                        top: 28px;
                    }
                }
            }

            .header {
                .brand {
                    width: calc(100% - 88px);
                    text-align: left;
                    padding-left: 15px;
                }
            }

            .main-nav {
                display: none;
            }

            &.open {
                bottom: 0;

                .menu-toggle {

                    span {
                        top: 21px;
                        transition: top .1s, opacity .1s .1s, transform .1s .1s;
                        transition-timing-function: ease-out;

                        &:nth-child(1) {
                            transform: rotate(45deg);
                        }
    
                        &:nth-child(2) {
                            opacity: 0;
                        }
    
                        &:nth-child(3) {
                            transform: rotate(-45deg);
                        }
                    }
                }

                .main-nav {
                    display: flex;
                }
            }
            
            .footer {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

            .user-open .menu {
                display: none;
            }

            .user-menu {
                top: 0;
                overflow-y: auto;
                margin: 0;
                background: none;
                border: 0;
                padding: 15px 0;
            }

            .dropdown-divider {
                opacity: 0.2;
            }
        }

        .content {
            margin-top: 45px;
            padding-top: 10px;
        }
    }
}

@media only screen and (max-height: 768px) {

    .page-layout .sidebar {

        .user-open .menu {
            display: none;
        }

        .user-menu {
            top: 0;
            overflow-y: auto;
            margin: 0;
            background: none;
            border: 0;
            padding: 15px 0;
        }

        .dropdown-divider {
            opacity: 0.2;
        }
    }
}

