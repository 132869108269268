table.rack {
    font-size: 12px;
    table-layout: fixed;
    width: 100%;
    td {
        padding: 0;
    }
    tr {
        height: 2em;
    }
    td.number-col {
        padding-right: 0.5em;
        width: min-content;
        height: 4em;
        text-align: right;
    }
    td:not(.number-col) {
        border: 3px solid black;
        color: white;
        background-color: $grey;
        padding-left: 0.5em;
        padding-right: 0.5em;
        cursor: pointer;
        > div {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @for $i from 1 through 48 {
                &.line-clamp-#{$i} {
                    -webkit-line-clamp: $i;
                }
            }
        }
        &:hover {
            background-color: $tertiaire;
        }
    }
    td.device {
        background-color: $secondary;
        position: relative;
        background-clip: padding-box;
        .delete-btn {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0;
            .btn {
                line-height: 1;
            }
        }
        &:hover .delete-btn {
            opacity: 1;
        }
    }
    td.empty {
        > div {
            opacity: 0;
        }
        &:hover > div {
            opacity: 1;
        }
    }
}