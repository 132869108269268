.admin-ipsets {
	&-delete {
		color: $danger;
		font-weight: bold;
	}
	&-transparent {
		background-color: transparent;
		border-color: transparent;
		cursor: pointer;
	}
}
