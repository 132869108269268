.web-scanner-result{
    .scan-date{
        text-align: right;
    }
    .scan-score{
        text-align: center;
    }
    .scanner-result{
        margin-bottom: 2em;
        .title {
            font-weight: 600;
            text-transform: capitalize;
        }
    }
}

.search-animation-container {
    width: 100%;
    position: relative;
    margin-top: 50px;
    margin-top: 55px;
    .search-animation-txt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .search-file {
      position: absolute;
      left: 50%;
      top: -25px;
      transform: translateX(-50%);
      font-size: 125px;
    }
    .search-animation{
      left: calc(50% - 90px);
      width: 100px;
      height: 100px;
      transform-origin: 100% center;
      position: relative;
      animation: search-rotate 2s linear infinite;
      .search-icon {
        color: #000;
        position: absolute;
        height: 100%;
        width: 100%;
        animation: search-rotate 2s linear reverse infinite;
      }
    }
  }
  
  @keyframes search-rotate {
    100% { 
      transform: rotate(-360deg);
    } 
  }