.notification-widget {
    .post-it {
        color: #000;
        background-color:#ffc;
        padding:1em;
        margin-bottom: 1em;
        /* Firefox */
        -moz-box-shadow:2px 2px 7px rgba(33,33,33,1);
        /* Safari+Chrome */
        -webkit-box-shadow: 2px 2px 7px rgba(33,33,33,.7);
        /* Opera */
        box-shadow: 2px 2px 7px rgba(33,33,33,.7);
        font-size: 18px;
        font-family:"Indie Flower","Reenie Beanie",arial,sans-serif;

        p {
            color: #000;
            font-family:"Indie Flower","Reenie Beanie",arial,sans-serif;
        }
        a:not(.read-more) {
            text-decoration: underline;
            color: $text-link-color;
        }
    }
}

.user-widget {
    font-size: 14px;
}

.dash-button {
    overflow: hidden;
    .image-container{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        transition: all .3s;
        @for $i from 1 through 12 {
            &.size-#{$i} {
                height: ($i+3)+em;
            }
        }
    }
    @for $i from 12 through 52 {
        .font-size-#{$i} {
            font-size: ($i)+px;
        }
    }
    position: relative;
    display: inline-block;
    background-color: #000a28;
    width: 100%;
    color: #fff;
    margin-bottom: 1em;
    border-radius: 5px;

    .dash-button-label{
        position: absolute;
        bottom: 0.5em;
        width: 100%;
        font-weight: 800;
        background-color: rgba(0, 10, 40, 0.7);
        padding: 0.2em 0.4em;
        .icon { 
            padding-left: 2px;
            padding-top: 4px;
            color: $tertiaire;
        }
    }
    &:hover, &:focus{
        color: $tertiaire;
        .image-container{
            transform: scale(1.1);
        }
        
    }
}

.youtube-container-aspect {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    background:#000;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.pdf-container-aspect{
    position: relative;
    width: 100%;
    padding-bottom: 150%;
    background:#000;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.available-admins-widget {
    
    .available-admin {
        display: flex;
        align-items: center;
        font-size: 90%;

        .circle-img {
            border-radius: 50%;
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }
}

.zone-under-attack-widget {
    .ddos-zone {
        border-radius: 30px;
        font-weight: 800;
        text-align: center;
        display: inline-block;
        background-color: $danger;
        animation-name: bg-flash;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        margin-bottom: 1em;
        padding: 1em;
        width: 100%;
        font-size: 20px;
        &:hover {
            text-decoration: none;
        }
    }
}

.credits-widget {
    background-color: #FFF;
    border-radius: 5px;
    padding: 1em;
    .credit-title {
        font-size: 16px;
        padding: 0;
        line-height: 0;
    }
    .credit-amount {
        background-color: $grey;
        padding: 4px;
        text-align: center;
        margin-bottom: 0.5em;
    }
}
.bg-qbine {
    background-image: url("../img/backgrounds/bg-qbine.jpg");
}

.bg-ipv4 {
    background-image: url("../img/backgrounds/bg-ipv4-management.jpg");
}
.bg-eurostack {
    background-image: url("../img/backgrounds/bg-qbine.jpg");
}

.bg-ddos {
    background-image: url("../img/backgrounds/bg-ddos-protection.jpg");
}

.bg-flowspec {
    background-image: url("../img/backgrounds/bg-flowspec.jpg");
}

.bg-cards {
    background-image: url("../img/backgrounds/bg-datacenter-access.jpg");
}

.bg-data-traffic{
    background-image: url("../img/backgrounds/bg-data-traffic.jpg");
}

.bg-support{
    background-image: url("../img/backgrounds/bg-support.jpg");
}

.bg-datacenter-access-new{
    background-image: url("../img/backgrounds/bg-dronten.jpg");
}

.bg-datacenter-access-old{
    background-image: url("../img/backgrounds/bg-meppel.jpg");
}

@media (min-width: 992px) {
    .sticky-sidebar-active {
        position: fixed;
        overflow-y: auto;
        bottom: 0;
        pointer-events: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        & > * {
            pointer-events: all;
        }
    }
}

.has-more {
    max-height: 250px;
    overflow: hidden;
    position: relative;

    .read-more {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
        padding-top: 2rem;
    }
}

#inputGroupAppendVerificationToken {
    .input-group-text {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        padding: 3px;
    }

    .btn {
        padding: 0;
        background: transparent;
        color: #ff6600;
        border: none;
    }
    .form-control[readonly] {
        background: #FFFFFF;
        color: #999999;
    }
}