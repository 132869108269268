$primary: #2b2e33;
$primary-dark: #141517;
$primary-light: #7f8184;
$secondary: #000a28;
$secondary-light: #253155;
$secondary-dark: #1a1a63;
$tertiaire: #ff6600;
$tertiaire-dark: #ee7800;
$tertiaire-darker: #a65400;
$highlight: #319db5;
$highlight-dark: #267a8d;
$grey-light: #f2f2f2;
$grey: #e5e5e5;
$grey-dark: #808080;
$success: #18a689;
$success-contrast: #ffffff;
$success-dark: #13846d;
$danger: #c75757;
$danger-contrast: #ffffff;
$danger-dark: #9f4545;

$warning: #ffdd44;
$warning-contrast: #000000;
$warning-dark: #bda432;
$warning-contrast: $primary;

$info: $secondary-dark;
$info-contrast: #ffffff;

$severity-low: $warning;
$severity-medium: $tertiaire-dark;
$severity-high: $danger;

$text-link-color: $tertiaire;
$text-sidebar: #dbdbdb;
$btn-primary: $tertiaire;
$btn-primary-border: $tertiaire-dark;
$acitve-menu-item-color: $tertiaire;
$hover-menu-item-color: $tertiaire;

$sidebar-bg-color: #dbdbdb;
$sidebar-color: #6d6f71;
$highlight-color: #d8d8d8;
$submenu-bg-color: #dbdbdb;
$submenu-bg-color-collapsed: #dbdbdb;
$icon-bg-color: #dbdbdb;
$usermenu-border-color: #4b587e;
// sidebar en niet bootstrap
$breakpoint-xl: 1660px !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1260px,
    xxl: 1900px,
);

$container-max-widths: (
    sm: 540px,
    md: 470px,
    lg: 690px,
    xl: 900px,
    xxl: 1540px,
);
