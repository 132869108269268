@font-face {
  font-family: montserrat;
  src: url(../fonts/montserrat-v14-latin-regular.woff);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(../fonts/montserrat-v14-latin-800.woff);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Reenie Beanie;
  src: url(../fonts/ReenieBeanie-Regular.ttf);
}

@font-face {
  font-family: Indie Flower;
  src: url(../fonts/IndieFlower-Regular.ttf);
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 10055 !important;
}
html{
  height: 100%;
}
body{
  font-family: 'montserrat', sans-serif;
  background:   #F5F5F5;
  height: 100%;
}

.table-layout-auto, .react-bootstrap-table table.table-layout-auto{
  table-layout: auto;
}

#root{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100% !important;
  max-width: 1590px !important;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 2170px) {
  .container {
    width: 85% !important;
    max-width: 3000px !important;
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container > .container {
  padding: 0 !important;
  width: 100% !important;
}

a,
a:hover,
a:focus{
  color:            inherit;
  text-decoration:  none;
}

a.link,
p a,
p a:hover,
p a:focus {
  text-decoration: underline;
  color: inherit;
  &:hover {
    color: $tertiaire;
  }
  
}

.hide-controls::-webkit-inner-spin-button,
.hide-controls::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

.amount-input {
  width: 60px !important;
  pointer-events: none;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.feature-icon {
  display: flex;
  width: 140px;
  height: 120px;
  background: $success;
  color: $white;
  align-items: center;
  font-size: 30px;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  .inner-icon{
    display: block;
    width: 100%;
    padding-top: 5px;
    svg {
      margin: 0 auto;
      display: block;
    }
    span {
      font-size: 12px;
    }
  }
}
.feature-icon:hover {
  background: $success-dark;
}

.insights {
  border: none;
}

.add-website {
  background:#dbdbdb;
  color: $secondary;
  .card-header {
    button {color: #6d6f71; }
  }

}

.card-header .card-title {
  margin-bottom: 0;
}

.card-header-tabs {
  margin-bottom: 0;
}

.card.primary {
  .card-header, .card-footer {
    color: $white;
    background-color: $primary;
  }
  .card-header .btn-link {
    color: $white
  }
}
.card.secondary {
  .card-header, .card-footer {
    color: $white;
    background-color: $secondary;
  }
  .card-header .btn-link {
    color: $white
  }
}

.card.tertiaire {
  .card-header, .card-footer {
    color: $white;
    background-color: $tertiaire;
  }
  .card-header .btn-link {
    color: $white
  }
}

#copyArea {
  position:fixed;
  left:-10000px;
  top:-10000px;
}

h3.graph-title {
  font-size: 18px;
  font-weight: bold;
  color: #696969;
}

.token {
  margin: 10px 0px 30px;

}

.bg-transparent {background-color: transparent;}
.bg-tertiaire {background-color: $tertiaire;}
.bg-secondary {background-color: $secondary}
.bg-dark-grey {background-color: $sidebar-bg-color}
.bg-primary, .btn-primary { background-color: $primary; }
.btn-primary, .btn-primary:not(:disabled):not(.disabled):focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{ 
  background-color: $btn-primary; 
}
.btn-toggle-group { 
  background-color: $tertiaire; 
}
.btn-toggle-group:active, 
.btn-toggle-group:focus,
.btn-toggle-group:hover,
.btn-toggle-group:not(:disabled):not(.disabled):active, 
.btn-toggle-group:not(:disabled):not(.disabled).active, 
.show > .btn-toggle-group.dropdown-toggle { 
  background-color: $tertiaire-darker; 
}
.btn-primary:not(:disabled):not(.disabled):hover { background-color: $btn-primary-border;}
.bg-success { background-color: $success; }
.btn-danger, .bg-danger {
  background-color: $danger;
  color:            #fff;
}

.btn-disabled{
  background-color:$primary-light;
  color:$grey;
  cursor:wait;
  &:hover{
    background-color:$primary-light;
    color:$grey;
    cursor:wait;

  }
}
.btn:active, .btn:focus {
  border: 1px solid transparent;
  }

.dropdown-menu.show {
    background-color: $primary-dark;
    border:           none;
    margin-left:      -6px;
    margin-top:       11px;
  }
.dropdown-menu {
  -webkit-border-radius:  unset;
  -moz-border-radius:     unset;
  border-radius:          unset;
  padding:                0px;

  .dropdown-item {
    color:    #fff;
    padding:  5px 15px;
  }

  .dropdown-item:hover {
    background: $tertiaire;
    color:      #fff;
  }
}
.btn-danger:hover { background-color: $danger-dark; }
table td.reset-expansion-style{ padding:0 }
.search-table {
  tr { line-height: 36px;}
  table tbody .q-links a {
    color: $highlight;
  }
  table tbody .q-links a::after {
    display:                inline-block;
    content:                '';
    -webkit-border-radius:  0.15rem;
    border-radius:          0.15rem;
    height:                 0.3rem;
    width:                  0.3rem;
    background-color:       $highlight;
    margin:                 0px 5px 2px 9px;
  }
  table tbody .q-links a:last-child::after {
    display: none;
  }
  .dropdown-toggle::after { display: none; }
}

.react-bootstrap-table {
  table {
    thead {
      background-color: $white;
      th {
        border-top: none;
      }
    }
  }
}
.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

.waf-content {
  min-height: 100%;
  .container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    & > div:first-child {
      flex-grow: 1;
    }
  }
}

p.terms-text {
  font-size: 14px;
  color:  $primary-light;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.info-tooltip {
  border-radius: 30px;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 12px;
  border: none;
}

.warning-tooltip {
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 12px;
  border: none;
  color: $danger;
  &:hover{
    color: $danger-dark;
  }
}

.tooltip {
  z-index: 9999
}

table tbody {
  span.pointer {
    cursor: pointer;
  }
}

table.table-fixed {
  table-layout: fixed;
}

.overflow-long-word {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (hover: none) {
  .overflow-long-word {
    overflow-x: scroll;
    text-overflow: initial;
  }
}

.search-label {
  display: block;
}

.line{
  width:          100%;
  height:         1px;
  border-bottom:  1px dashed #ddd;
}

.wrapper {
  width:        100%;
  display:      block;
  align-items:  stretch;
}


.clickable {
  cursor: pointer;
}

@media (min-width: 576px){
  .new-website span{ display: none; }
}
@media (min-width: 768px) {
  .new-website span{ display: contents; }
}

a[data-toggle="collapse"] { position: relative; }

ul ul a {
  font-size:    0.9em;
  background:   $secondary;
}

button.success,
a.success {
  background: $success;
  color:      #ffffff;
}

button.success:hover,
a.success:hover {
  background: $success-dark;
  color:      #ffffff;
}

a.faq,
a.faq:hover {
  background: $highlight;
  color:      #fff ;
}

/* bootstrap hack: fix content width inside hidden tabs */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: block;     /* undo display:none          */
  height: 0;          /* height:0 is also invisible */ 
  overflow-x: hidden; /* no-overflow                */
}
.tab-content > .active,
.pill-content > .active {
  height: auto;       /* let the content decide it  */
} /* bootstrap hack end */

.modal{ z-index: 9999; }
.modal-header {
  .close {
    color: inherit;
  }
}

footer {
  flex-shrink: 0;
  background-color: $secondary;
  border-top: 1px solid #ff6600;
  color: white;
  position: relative;
  bottom: 0;
  min-height: 130px;
  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .company {
    border-right: 2px solid #ffffff;
    flex-grow: 0;
    font-size: 14px;
    span { font-size: 16px; }
    img {
      height: 53px;
    }
  }
  .contact {
    flex-grow: 0;
    h4 {
      margin: 0;
      color: #ffffff;
      span { color: #ff6600; }
    }
    a:hover { color: #23527c; }
  }
}

svg:not([aria-labelledby]) {
  pointer-events: none;
}

.sortable {
  cursor: move;
  label {
    &:not([for]) {
      pointer-events: none;
    }
    .info-tooltip {
      pointer-events: initial;
    }
  }
}

.sortable-container {
  pointer-events: auto !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &.dragging {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
      pointer-events: auto !important;
  }
}

.custom-control {
  z-index: unset;
}
.custom-control-label::before,
.custom-control-label::after{
  position:absolute;
  top:50%;
  transform: translateY(-50%);
}

.custom-switch{
  .custom-control-label::after{
    top:50%
  }
  .custom-control-label {
    min-height: 1.5em;
  }
  .custom-control-input{
    top:50%;
    z-index:2;
    transform: translateY(-50%);
    width:100%;
    pointer-events: none;
  }
  .custom-control-input:checked ~ .custom-control-label::after{
    transform: translateY(-50%) translateX(0.75rem);
  }
}

textarea.auto-size {
  min-height: 6em;
}

.w-tc-editor-preview pre{
  color:inherit;
}

.custom-checkbox {
  h3 {
    font-size: 19px;
    font-weight: bold;
    line-height: unset;
  }
  .custom-control-label::before, .custom-control-label::after {
    margin-top: 5px;
  }
}

  .custom-control-label::after{
    @extend .bg-danger 
  }
  .custom-control-input:checked ~ .custom-control-label::before{
    border-color:$gray-500;
  }
  .custom-control-input:checked ~ .custom-control-label::after{
    @extend .bg-success 
  }


.was-validated .form-control:valid[type="number"], .form-control.is-valid[type="number"],
.was-validated .form-control:invalid[type="number"], .form-control.is-invalid[type="number"] {
  padding-right: 1rem;
}
.modal-dialog.wide{
  @extend .container;
  .modal-content{
    margin-right:17px;
  }
}

.move-up{
  transform: translateY(-100%);
  margin-bottom:-100%;
}

.messagebody {
  all: initial;
  font-family: montserrat;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important;
}
.react-datepicker-popper {
  z-index: 99999;
  .react-datepicker .react-datepicker__triangle{
    left: -136px !important;
  }
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 3px;
}
.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  margin-right: 16px;
}

.popup-alert.alert {
  padding-right: 30px;
  text-align: center;
  -webkit-box-shadow: 0 2px 10px 6px #666;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    0 2px 10px 6px #666;  /* Firefox 3.5 - 3.6 */
  box-shadow:         0 2px 10px 6px #666;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.v-collapse {
  visibility: collapse;
}

.bg-flash {
	animation-name: bg-flash;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

@keyframes bg-flash {
  	50%{
  		background-color: transparent;
	}
} 

.bg-severity-low {
  background-color: $severity-low;
}

.bg-severity-medium {
  background-color: $severity-medium;
}

.bg-severity-high {
  background-color: $severity-high;
}

.border-severity-low {
  border-color: $severity-low;
}

.border-severity-medium {
  border-color: $severity-medium;
}

.border-severity-high {
  border-color: $severity-high;
}

.btn{
  position:relative;
  overflow:hidden;
  .loader{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
  }
}
.loader{
  display:block;
  width:100%;
  height:3px;
  border-radius:2px;
  background:$grey;
  position:relative;
  overflow:hidden;
  font-size:8px;
  >.beam{
    display:block;
    width:20%;
    height:100%;
    background:lighten($tertiaire, 20%);
    position:absolute;
    left:-20%;
    top:0;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-delay: -4s;
    animation-name: loader-bar;
    z-index:1;
  }
  >.inner{
    color:#FFFFFF;
    padding-left:5px;
    position:relative;
    z-index:2;
  }
  &.primary{
    background:$primary-light;
    >.beam{
      background:$primary;
    }
    >.inner{
      color:$grey-light;
    }
  }
  &.secondary{
    background:$primary-light;
    >.beam{
      background:$secondary;
    }
    >.inner{
      color:$grey-light;
    }
  }
  &.tertiaire{
    background:$secondary;
    >.beam{
      background:$tertiaire;
    }
    >.inner{
      color:$grey-light;
    }
  }
  &.light{
    background:$grey;
    >.beam{
      background:$primary-light;
    }
    >.inner{
      color:$primary;
    }
  }
  &.lighter{
    background:$grey-light;
    >.beam{
      background:$grey;
    }
    >.inner{
      color:$primary;
    }
  }
}


@keyframes loader-bar {
  0%{
    left:-20%;
	}
  100%{
    left:100%;
	}
} 
.statuspages-logo{
  max-width:150px;
  width:20%;
  position:relative;
  img{
    max-height:100%;
    max-width:100%;
    z-index:1;
    position:absolute;
  }
  button{
    position:absolute;
    z-index:2;
    top:5px;
    right:5px;
    background-color:rgba(51,51,51,.1);
  }
}

button.statuspages-reset-colors{
  float:right;
}

.tooltip-inner p{
  color:#ffffff;
}

img.no-graph-img{
  max-width:620px;
}

.form-control-wrapper{
  &.is-valid {
    [class$="-control"] {
      border-color: $success;
    }
  }
  &.is-invalid{
    [class$="-control"] {
      border-color: $danger;
    }
  }
}


.small-col-gutter{

  padding-left:$grid-gutter-width/5;
  padding-right:$grid-gutter-width/5;
}

.text-field{
  margin-bottom:2em;
  a{
    color:$text-link-color;
    &:hover{
      text-decoration:underline;
    }
    &:visited{
      color: lighten($text-link-color, 20%);
    }
  }
  b, strong{
    font-weight: bold;
  }
  table{
    width:100%;
  }
}
.terms-form{
  display:flex;
  position: relative;
  justify-content: flex-end;
  .form-group{
    display:inline-block;
    display:flex;
    align-items: center;
    margin:0;
  }
  .form-control{
    display:inline-block;
    flex-grow: 0;
    flex-shrink: 0;
  }
  button{
    flex-grow: 0;
    flex-shrink: 0;
  }
  .invalid-feedback{
    display: inline-block;
    position: absolute;
    bottom: -10px;
    text-align: left;
  }
  .terms-label{
    margin:0;
    padding:0px 15px;
    color:$danger;
    text-align:left;
  }
}
.checkbox-sm{
  width:18px;
  height:18px;
}

$storage-indicator-rad:      0px;
.storage-indicator-legend{

  &--used{
    background:$danger;
  }
}
.storage-indicator{
  position:relative;
  display:flex;
  width:100%;
  border-spacing:0;
  border-radius:$storage-indicator-rad;
  // padding-left:$storage-indicator-rad;
  border: 0px solid #000000;
  box-sizing:content-box;
  flex-grow:0;
  .btn{
    
    padding: 0px 5px;
    margin-top: 4px;
    vertical-align: top;
    right: 4px;
    position: absolute;
    font-size:1.2em;
    z-index:2;
  }
  
  &__text{
    box-sizing:content-box;
    position:relative;
    padding-left:1em;
    font-size:12px;
    color:#FFF;
    font-weight:bold;
    max-width:100%;
    overflow:hidden;
    white-space: nowrap;
    line-height:inherit;
    &--free{
      color:#000000;
    }
  }
  &__part{
    flex-shrink:1;
    width:0px;
    position:relative;
    display:block;
    float:left;
    height:100%;
    box-sizing:content-box;
    // margin-left:-$storage-indicator-rad;
    // padding-left:$storage-indicator-rad;
    line-height:inherit;
    &--used{
      background:$danger;
      z-index:3;
    }
    &--assigned{
      background:$secondary;
      z-index:2;
    }
    &--free{
      background:#cccccc;
      z-index:1;
    }
    &:first-child{
      border-top-left-radius:$storage-indicator-rad;
      border-bottom-left-radius:$storage-indicator-rad;
    }
    &:last-child{
      border-top-right-radius:$storage-indicator-rad;
      border-bottom-right-radius:$storage-indicator-rad;
    }
  }
  &--editable{
    position:relative;
    display:block;
    background:transparent;
    float:left;
    height:100%;
    .storage-indicator__part{
      &:first-child{
        border-top-left-radius:$storage-indicator-rad;
        border-bottom-left-radius:$storage-indicator-rad;
      }
      &:last-child{
        border-top-right-radius:$storage-indicator-rad;
        border-bottom-right-radius:$storage-indicator-rad;
      }
    }
  }
  &__control{
    position:relative;
    display:block;
    float:left;
    height:100%;
    width:10px;
    // left:-(5px+$storage-indicator-rad);
    left:-5px;
    margin-right:-10px;
    overflow:visible;
    z-index:4;
    cursor:col-resize;
    > div{
      background:#666;
      width:1px;
      position:relative;
      left:5px;
      overflow:visible;
      height:110%;
      top:-5%;
      filter: drop-shadow(3px 1px 3px rgba(0,0,0,0.25));
      &:before{
        content:'';
        position:absolute;
        left:-4.5px;
        top:0;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        
        border-top: 7px solid #666;
        filter: drop-shadow(3px 1px 3px rgba(0,0,0,0.25));
      }
      &:after{
        content:'';
        position:absolute;
        left:-4.5px;
        bottom:0;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        
        border-bottom: 7px solid #666;
        filter: drop-shadow(3px 1px 3px rgba(0,0,0,0.25));
      }
    }
  }
  &.extra-lables{
    width:100%;
    border:0;
    margin-top:1em;
    display:block;
    .storage-indicator{
      &__text{
        position:absolute;
        color:#000;
        left:50%;
        transform:translateX(-50%);
        top:-8px;
        background-color:#F5F5F5;
        padding:0px 5px;
        overflow:visible;
        line-height:16px;
      }
      &__line{
        overflow:visible;
        line-height:40px;
        position:relative;
        border:1px solid #000;
        border-bottom:0px;
        border-top-right-radius:$storage-indicator-rad;
        border-top-left-radius:$storage-indicator-rad;
        &:before{
          content:"\00a0";
        }
      }
    }

  }
  &__spacer{
    padding-left:12px;
  }
  &.free-labels{
    margin-bottom:4em;
    border-top-left-radius:0;
    border-top-right-radius:0;
    *{
      overflow:visible;
      border-top-left-radius:0;
      border-top-right-radius:0;
    }
    .storage-indicator{
      &__text{
        max-width:initial;
        position:absolute;
        padding:0;
        &__container{
          width:100%;
          max-width:100%;
        }
        &.push{
          position:absolute;
          max-width:initial;
          color:#000;
          transform: translateY(80%) translateX(-50%);
          left:50%;
          &.depth-2{
            transform: translateY(140%) translateX(-50%);
          }
          &.depth-3{
            transform: translateY(200%) translateX(-50%);
          }
          &-left{
            left:0;
            transform: translateY(80%) translateX(0%);
            &.depth-2{
              transform: translateY(140%) translateX(0%);
            }
            &.depth-3{
              transform: translateY(200%) translateX(0%);
            }
          }
          &-right{
            right:0;
            left:auto;
            transform: translateY(80%) translateX(0%);
            &.depth-2{
              transform: translateY(140%) translateX(0%);
            }
            &.depth-3{
              transform: translateY(200%) translateX(0%);
            }
          }
        }
      }
    }
  }
}

.storage-user-stats{
  max-width:500px;
}


.tiny-table{
  td, th{
    padding:0 5px;
    line-height:1.8rem;
    border:0;
    vertical-align:baseline;
  }
  thead th{
    border:0
  }
}

.tiny-table{
  th, td{
    .form-control{
      height: 2rem;
      width: 100%;
      margin: 0px -5px;
      box-sizing: content-box;
      padding: 0px 5px;
      border-radius:0;
      border:0;
      line-height:1.8rem;
    }
    form{
      margin: 0px -5px;
      .form-control{
        margin:0;
        box-sizing: border-box;
      }
      .row{
        margin: 0px -5px;
        .col{
          padding:0px 5px;
        }
      }
    }
  }
}

.react-bootstrap-table .table thead th {
  background-color: #f5f5f5;
  color: #505050;
  font-size: 0.9rem;
}

.react-bs-table-no-data {
  color: grey;
}

.table-striped tbody tr:nth-of-type(odd){
  background:initial;
}
.table-striped tbody > tr:nth-of-type(odd){
  background-color: rgba(0, 0, 0, 0);
}
.table-striped tbody > tr:nth-of-type(even){
  background-color: rgba(0, 0, 0, 0);
}
.table-striped.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.alert .btn-close{
  position:absolute;
  top:0px;
  right:0px;
}


.btn-copy{
  // transition-duration:0;
  transition-duration:1s;
  transition-timing-function: ease;
  background-color:$tertiaire;
  transition-property: background-color;
  &.is-copied{
    transition-duration:0s;
    background-color:$success;
  }
}

.stats-bar-elem{
  width:100%;
  overflow:hidden;
  border:1px solid #000;
  border-radius:5px;
  padding:5px;
  &-block{
    padding:0px 5px;
    border:1px solid #999999;
    top: -5px;
    left: -5px;
    position: relative;
    .stats-bar-elem-block{
      border-left:0;
      border-top-left-radius:0;
      border-bottom-left-radius:0;
    }
  }
  &-labels{
    .stats-bar-elem-label{
      white-space: nowrap;
      padding:5px;
    }
  }
  &-label-space{
    width:100%;
    border:0px;
    padding:11px 0px;
  }
  &-total{
    background-color:#999;
    
  }
  &-used{
    background: $danger;
    color: $danger-contrast;
  }
  &-unused{
    background: $success;
    color: $success-contrast;
  }
  &-assigned{
    background: $warning;
    color: $warning-contrast;
  }
  &-free{
    background: #cccccc;
    color: #000000;
  }
  &-total{
    background: $grey-light;
    color: #000000;
  }
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #2b2e33;
  border-color: #2b2e33;
}

.ip-status {
  font-weight: bold;
  color: #CCCCCC;
  &.ddos-protected {
    color: #18A689
  }
  &.nulled {
    color: #FFC107;
  }
  &.auto-nulled, &.autonulled {
    color: #C75757;
  }
  &.admin-nulled, &.adminnulled {
    color: #FF4757
  }
  &.bypass {
    color: #AAAAAA;
  }
  &.ddos-protection-disabled {
    color: #AAAAAA;
  }
}

.ip-change-logs {
  .action {
    font-weight: bold;
    text-transform: capitalize;
  }
  .ip-change-log {
    .title {
      font-weight: bold;
    }
    &.changed {
      .old {
        background-color: #FF000090;
      }
      .new {
        background-color: #00FF0090;
      }
    }
  }
}

.card {
  .card-header.toggle {
      position: relative;
      padding-right: 36px;
      .accordion-toggle-icon{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1.25rem;
          .svg-inline--fa {
              transition: transform .2s ease;
          }
      }
      &.open .accordion-toggle-icon .svg-inline--fa{
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
      }
  }
  &.overflow-visible {
    overflow: visible;
  }
  .card-header.inactive {
    opacity: 0.5;
  }
  .card-body.inactive {
    opacity: 0.5;
    background-color: #ffffff7e;
  }
}



.form-action-label{
  line-height:2;
}
.pricing-form{
  small{
    overflow-wrap: break-word;
  }
}

html.dragging{
  touch-action: none;
  *{
    cursor: move !important;
    touch-action: none;
  }
}
.draggable-row{
  touch-action: none;
  *{
    touch-action: none;
  }
}
.react-bootstrap-table{
  .table{
    .selected-row{
      background:$secondary;
      color:#FFF;
    }
    .dragging-row{
      opacity:.3;
      position: relative;
      left:10px;
      z-index:2;
    }
  }

}

.paginated-select-field{
  @extend .form-control;
  position: relative;
  cursor:pointer;
  &.disabled{
    @extend .form-control, :disabled;
  }
  label{
  }
  .btn{
    position:absolute;
    right:3px;
    top:3px;
  }
}

form.dynamic-form {
  label.form-label {
    font-weight: 600;
  }
}

.show-whitespace {
  white-space: pre-line;
}

.document-container {
  background-color: #777;
  aspect-ratio: 0.7;
  position: relative;
  overflow-y: scroll;
  .react-pdf__Page {
    margin: 10px;
  }
  > p {
    color: #FFF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
  .loader {
    position: absolute;
    height: 10px;
    top: 0;
  }
  .document-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #00000050;
  }
}

.billable-item {
  .label {
    overflow: hidden;
    max-height: 3em;
  }
}

.nav-tabs {
  .nav-item {
    .nav-link { 
      &.active, &:hover {
        border-bottom: 2px solid #ff6600 !important;
      }
      &.active {
        background-color: #f5f5f5;
      }
    }
  }
}

.delete-button {
  background-color: #efefef;
  border-color: #adadad;
  color:#212529;
}

.delete-button > svg {
  color: #606060;
}

.delete-button:hover {
  color: white;
  transition: color 0s;
  background-color: $danger;
}

.delete-button:hover > svg {
  transition: color 0s;
  color: white;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.65rem;
}

thead > tr > th {
  font-weight: 400;
}
.form-control,
.paginated-select-field,
.paginated-select-field.disabled {
  &.small {
    @extend .small
  }
}
.copy-wrapper {
  position: relative;
  box-shadow: 0px 0px 10px 3px transparent;
  transition-duration: 5s;
  border-color: transparent;
  border-radius: 0.25rem;
  overflow:hidden;

  &::after {
    content: attr(title) " copied to clipboard";
    @extend .small;
    @extend .alert-success;
    pointer-events: none;
    z-index: 2;
    transition-duration: 3s;
    transition-delay: 2s;
    display: block;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    max-width: 100%;
    padding: .5rem;
    border-radius: 5px;
    opacity: 0;
  }

  * {
    position: relative;
    z-index: 1;
    transition-duration: 5s;
  }

  &.is-copied {
    transition-duration: 0s;
    border-color: $success;
    box-shadow: 0px 0px 10px 3px $success;

    * {
      border-color: $success !important;
      transition-duration: 0s;
    }

    &::after {
      transition-duration: 0s;
      transition-delay: 0s;
      opacity: 1;
    }
  }
}