.toolbar-nav-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
    > div:last-child {
        display: flex;
        flex: 1;
        overflow: auto;
        @media (min-width: 768px) {
            overflow: hidden;
        }
    }
}