
  h2 a {
    font-size:  18px;
    color:      $highlight;
  }
  .bg-card-container >.card{
    border:0;
    overflow:hidden;
    transition:filter .2s;
    &:hover{
      transition:filter .1s;
      filter: drop-shadow(0px 0px 2px #000a28);
    }
  }
  
  .card-background {
    background-size:cover;
    position:relative;
    height:250px;
    padding-left: 0;
    padding-right: 0; 
    border-radius: inherit;
    overflow:hidden;
    &:after{
      content:'';
      display:block;
      position:absolute;
      left:0;
      top:0;
      right:0;
      bottom:0;
      box-sizing:border-box;
      border:1px solid #000a28;
      border-radius: inherit;
    }
    &:before{
      content:'';
      display:block;
      position:absolute;
      background-color:#ffffff22;
      left:0;
      top:0;
      right:0;
      bottom:0;
      transition:opacity .5s;
      opacity:0;
    }
    &:hover{
      &:before{
        opacity:1;
        transition:opacity .2s;
      }
    }
  }

  .card-label {
    border-top:1px solid #333333;
    background-color: rgba(0,10,40,0.85);
    color: white;
    position: absolute;
    width: 100%;
    font-weight: 800;
    bottom: 0;
    padding: 0.55em 0.4em; 
  }

  .nav-tabs {
    font-weight: bold;
    .nav-link {
      background-color: transparent;
      color:            $primary-light;
      border: none;
      border-bottom: 2px transparent solid;
    }

    .nav-link.active,
    .nav-link:hover {
      color: $primary;
      border-bottom: 2px $highlight solid;
    }
    li a i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  table {
    tbody .q-links a {
      color: $highlight;
    }
    tbody .q-links a::after {
      display: inline-block;
      content: '';
      -webkit-border-radius: 0.15rem;
      border-radius: 0.15rem;
      height: 0.3rem;
      width: 0.3rem;
      background-color: $highlight;
      margin: 0px 5px 2px 9px;
    }
    tbody .q-links a:last-child::after {
      display: none;
    }
    .dropdown-toggle::after { display: none; }
  }

.editable-label {
  font-weight: 800;
  &:hover {
    &:after {
      content: "\f040";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      font-size: 50%;
      padding-left: 10px;
    }
  }
}

.website-screenshot {
  position:relative;
  padding-left: 0;
  padding-right: 0; 
  border:1px solid #000a28;
  border-radius: 0.25rem;
  overflow:hidden;
  &:after{
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    box-sizing:border-box;
    border:inherit;
    border-radius: inherit;
  }
  &:before{
    content:'';
    display:block;
    position:absolute;
    background-color:#ffffff22;
    left:0;
    top:0;
    right:0;
    bottom:0;
    transition:opacity .5s;
    opacity:0;
  }
  &:hover{
    &:before{
      opacity:1;
      transition:opacity .2s;
    }
  }
}

.top-talkers-row > td {
  font-size: 0.9rem;
  line-height: 1.2;
  vertical-align: middle;
  padding: 0.175rem;
  >a {
    text-decoration: none;
  }
}

.top-talkers-tag {
  background-color: $primary;
  border-radius: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-right: 0.5em;
  font-size: 0.8rem;
  color: white;
  &.whitelist{
    background-color: $success;
  }
  &.pass{
    background-color: $warning-dark;
  }
  &.validate{
    background-color: $grey-dark;
  }
  &.ratelimit{
    background-color: $grey-dark;
  }
  &.blacklist{
    background-color: $danger;
    font-weight: 900;
  }
}

.top-talkers-icon,
.top-talkers-icon:hover {
  width: 0.25em;
  &.disabled {
    cursor: default !important;
    opacity: 0.25;
  }
  &.transparent {
    opacity: 0.0;
  }
  &.sm {
    height: 0.8em !important;    
  }
  &.search {
    width: 1.65em;
    display: inline-block;
    vertical-align: super; 
  }
  &.gray {
    color: gray;
  }
}

.ml-auto.navbar-nav, .mr-auto.navbar-nav{
    flex-wrap: wrap;
}

.dropdown-time-selection > button {
  background-color: lightgray;
  height: 2.2em;
  color: black;
}

.top-talkers-cidr-selection {
  width: 100%;
}