$spaceamounts: (5, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space * 0.25}rem !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space * 0.25}rem !important;
        }
    }
}

.bg-center {
    background-position: center !important;
}

.bg-contain {
    background-size: contain !important;
}
